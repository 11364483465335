export const MFA_ACTIONS = {
  postCode: 'submit',
  resendCode: 'resend',
  init: 'init',
};

export const MFA_ERRORS_KEYS = [
  'authentication.invalid_mfa_code',
  'authentication.expired_mfa_code',
];

export const MFA_NOT_REQUIRED = 'authentication.mfa_not_required';
