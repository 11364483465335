<script>
import get from 'lodash/get';
import join from 'lodash/join';
import moment from 'moment-timezone';
import Cookie from 'js-cookie';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DATE_FORMAT, STORAGE_NAMES } from '@emobg/web-utils';
import { authService } from '@emobg/access-utils';
import { MuiVerificationCode } from '@emobg/motion-ui/v1';

import { GenericModalComponent } from '@/components';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';
import VerificationCodeFeedback from './VerificationCodeFeedback';
import { MFA_ACTIONS, MFA_ERRORS_KEYS, MFA_NOT_REQUIRED } from '../const/index';
import { SHARED_URLS } from '../../../../router/RouterMap';
import {
  allowCookiesHandler,
  DEVICE_USER_COOKIE,
  sanitizeIdpUuid,
} from '../../../../constants/auth';

export default {
  name: 'VerificationCodeForm',
  components: {
    GenericModalComponent,
    MuiVerificationCode,
    VerificationCodeFeedback,
  },
  data() {
    return {
      code: '',
      verificationCode: [],
      isFormValid: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      user: state => state.user.data,
    }),
    ...mapState(DOMAINS_MODEL.auth.mfa, {
      action: state => state.actionFeedback.data,
      codeError: state => state.error,
      codeStatus: state => state.STATUS,
      codeData: state => get(state, 'data.data'),
    }),
    isCodeValid() {
      return join(this.verificationCode, '').length === 4;
    },
    hasErrors() {
      return MFA_ERRORS_KEYS.includes(get(this, 'codeError.key'));
    },
    errorText() {
      return this.codeError && !this.isCodeNotRequired ? getMessageText(this.codeError, this.$t, null) : '';
    },
    isCodeNotRequired() {
      return get(this, 'codeError.key') === MFA_NOT_REQUIRED;
    },
  },
  async created() {
    this.MFA_ACTIONS = MFA_ACTIONS;
    this.returnUrl = Cookie.get(STORAGE_NAMES.returnUrl) || SHARED_URLS.dashboard;
    await this.resendCode({ actionFeedback: MFA_ACTIONS.init });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.userAccount, [
      'setMFARequired',
    ]),
    ...mapMutations(DOMAINS_MODEL.auth.mfa, [
      'resetStore',
    ]),
    ...mapActions(DOMAINS_MODEL.auth.mfa, [
      'postCode',
      'resendCode',
    ]),
    clearErrors() {
      if (this.hasErrors) {
        this.resetStore();
      }
    },
    logOut() {
      authService.instance.clearCookies(allowCookiesHandler);
      authService.instance.logout();
    },
    async requestNewCode() {
      this.isLoading = true;
      await this.resendCode({ actionFeedback: MFA_ACTIONS.resendCode });

      if (this.isCodeNotRequired) {
        this.clearErrors();
        this.redirectToOrigin();
      }

      this.isLoading = false;
    },
    async sendCode() {
      this.isLoading = true;
      await this.postCode(join(this.verificationCode, ''));
      if (this.isCodeNotRequired) {
        this.redirectToOrigin();
      } else if (this.codeStatus.LOADED) {
        this.setDeviceCookie();
        this.redirectToOrigin();
      } else if (this.codeStatus.ERROR) {
        this.isLoading = false;
      }
    },
    redirectToOrigin() {
      this.setMFARequired(false);
      window.location = this.returnUrl;
    },
    setDeviceCookie() {
      if (get(this, 'codeData.userDeviceToken') && get(this, 'codeData.expirationDate')) {
        const idpUuid = sanitizeIdpUuid(get(authService, 'instance.authData.idp_uuid'));
        const deviceToken = get(this, 'codeData.userDeviceToken');
        const expiryDate = get(this, 'codeData.expirationDate');

        Cookie.set(`${DEVICE_USER_COOKIE}${idpUuid}`, deviceToken, {
          expires: moment.utc(expiryDate, DATE_FORMAT.filter).add(1, 'year').toDate(),
        });
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :size="SIZES.small"
    title="Verification code"
    class="VerificationCodeForm emobg-font-line-height-large"
    data-test-id="verification-code-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <VerificationCodeFeedback />
    </template>
    <template slot="body">
      <p>Please enter the 4-digit code which has been emailed to <span class="emobg-font-weight-semibold"> {{ user.email }} </span></p>
      <div class="d-flex flex-column mx-5 px-5 py-4">
        <MuiVerificationCode
          v-model="verificationCode"
          :error="errorText"
        />
      </div>
      <p class="pb-1">
        Not your account?
        <span
          class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
          data-test-id="change-account-link"
          @click="logOut"
        >
          Change your account
        </span>
      </p>
      <p>
        Code not received?
        <span
          :class="{ 'disabled': codeStatus.LOADING || isLoading }"
          class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
          data-test-id="resend-code-link"
          @click="requestNewCode"
        >
          Resend code
        </span>
      </p>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <ui-button
          :disabled="!isCodeValid || codeStatus.LOADING || isLoading"
          :loading="(codeStatus.LOADING && action === MFA_ACTIONS.postCode) || isLoading"
          class="wmin-initial"
          data-test-id="submit-button"
          @clickbutton="sendCode"
        >
          Submit
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
