<script>
import VerificationCodeForm from './components/VerificationCodeForm';
export default {
  name: 'MultiFactorAuthView',
  components: {
    VerificationCodeForm,
  },
};
</script>

<template>
  <div class="MultiFactorAuth">
    <VerificationCodeForm />
  </div>
</template>
