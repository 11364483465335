<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapMutations, mapState } from 'vuex';
import { COLORS, ICONS } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MFA_ACTIONS, MFA_ERRORS_KEYS, MFA_NOT_REQUIRED } from '../const';

export default {
  computed: {
    ...mapState(DOMAINS_MODEL.auth.mfa, {
      codeError: state => state.error,
      codeStatus: state => state.STATUS,
      action: state => state.actionFeedback.data,
    }),
    isFeedbackVisible() {
      return this.isError || (this.action === MFA_ACTIONS.resendCode && this.codeStatus.LOADED);
    },
    isError() {
      return !this.MFA_ERRORS_KEYS.includes(get(this, 'codeError.key')) && this.codeStatus.ERROR;
    },
    notificationType() {
      return this.isError ? COLORS.danger : COLORS.success;
    },
    iconType() {
      return this.isError ? ICONS.alertFull : ICONS.bold.check;
    },
    notificationText() {
      const successText = 'Verification code sent. Try again.';
      const errorText = `We could not <span class="emobg-font-weight-semibold">${this.action} code</span>! Try again.`;
      return this.isError ? errorText : successText;
    },
  },
  created() {
    this.MFA_ERRORS_KEYS = cloneDeep(MFA_ERRORS_KEYS);
    this.MFA_ERRORS_KEYS.push(MFA_NOT_REQUIRED);
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.auth.mfa, [
      'resetStore',
    ]),
  },
};
</script>
<template>
  <div
    v-if="isFeedbackVisible"
    class="VerificationCodeFeedback pt-3 w-100 position-relative"
    data-test-id="verification-code-feedback"
  >
    <ui-alert
      :color="notificationType"
      class="w-100 animated fadeIn pb-3 d-block"
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <ui-icon
            :color="notificationType"
            :icon="iconType"
          />
          <p
            class="mx-2"
            data-test-id="feedback-text"
            v-html="notificationText"
          />
        </div>
        <a
          class="emobg-link-primary emobg-body-2"
          data-test-id="dismiss-button"
          @click.prevent="resetStore"
        >
          Dismiss
        </a>
      </div>
    </ui-alert>
  </div>
</template>
