var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "VerificationCodeForm emobg-font-line-height-large",
        attrs: {
          size: _vm.SIZES.small,
          title: "Verification code",
          "data-test-id": "verification-code-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c("template", { slot: "alerts" }, [_c("VerificationCodeFeedback")], 1),
      _c("template", { slot: "body" }, [
        _c("p", [
          _vm._v("Please enter the 4-digit code which has been emailed to "),
          _c("span", { staticClass: "emobg-font-weight-semibold" }, [
            _vm._v(" " + _vm._s(_vm.user.email) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "d-flex flex-column mx-5 px-5 py-4" },
          [
            _c("MuiVerificationCode", {
              attrs: { error: _vm.errorText },
              model: {
                value: _vm.verificationCode,
                callback: function ($$v) {
                  _vm.verificationCode = $$v
                },
                expression: "verificationCode",
              },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "pb-1" }, [
          _vm._v(" Not your account? "),
          _c(
            "span",
            {
              staticClass:
                "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
              attrs: { "data-test-id": "change-account-link" },
              on: { click: _vm.logOut },
            },
            [_vm._v(" Change your account ")]
          ),
        ]),
        _c("p", [
          _vm._v(" Code not received? "),
          _c(
            "span",
            {
              staticClass:
                "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
              class: { disabled: _vm.codeStatus.LOADING || _vm.isLoading },
              attrs: { "data-test-id": "resend-code-link" },
              on: { click: _vm.requestNewCode },
            },
            [_vm._v(" Resend code ")]
          ),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled:
                    !_vm.isCodeValid || _vm.codeStatus.LOADING || _vm.isLoading,
                  loading:
                    (_vm.codeStatus.LOADING &&
                      _vm.action === _vm.MFA_ACTIONS.postCode) ||
                    _vm.isLoading,
                  "data-test-id": "submit-button",
                },
                on: { clickbutton: _vm.sendCode },
              },
              [_vm._v(" Submit ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }