var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isFeedbackVisible
    ? _c(
        "div",
        {
          staticClass: "VerificationCodeFeedback pt-3 w-100 position-relative",
          attrs: { "data-test-id": "verification-code-feedback" },
        },
        [
          _c(
            "ui-alert",
            {
              staticClass: "w-100 animated fadeIn pb-3 d-block",
              attrs: { color: _vm.notificationType },
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("ui-icon", {
                      attrs: {
                        color: _vm.notificationType,
                        icon: _vm.iconType,
                      },
                    }),
                    _c("p", {
                      staticClass: "mx-2",
                      attrs: { "data-test-id": "feedback-text" },
                      domProps: { innerHTML: _vm._s(_vm.notificationText) },
                    }),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "emobg-link-primary emobg-body-2",
                    attrs: { "data-test-id": "dismiss-button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.resetStore.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Dismiss ")]
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }